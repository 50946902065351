.btn-setting-agent {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  > button {
    margin-right: 10px;
  }
  button:nth-child(2) {
    background: #ff9900;
    color: #fff;
  }
}